<template>
	<div>
		<div class="myClient-text myClient-top-10 margin-left-xs margin-lr-lg">
			<el-select v-model="query.user_id" multiple placeholder="请选择员工名称">
				<el-option v-for="item in AccountList" :key="item.id" :label="item.full_name" :value="item.id">
				</el-option>
			</el-select>
			<el-date-picker v-model="value1"  type="daterange" range-separator="至" start-placeholder="开始日期"
				end-placeholder="结束日期" style="margin-left: 20px;" @change="timeChange">
			</el-date-picker>
		</div>
		<div style="margin-top: 10px; " class="margin-lr-lg">
			<as-list :ajaxMethods="{ list: 'accountQuerySign' }" ref="asList" :listQueryProp="query">
				<el-table-column type="selection" width="55"> </el-table-column>
				<el-table-column prop="user_name" label="员工姓名"></el-table-column>
				<el-table-column prop="school_name" label="学校名称"></el-table-column>
				<el-table-column prop="sign_time" label="签到时间">
					<template slot-scope="scope">
						{{scope.row.sign_time | dateTimeFilter}}
					</template>
				</el-table-column>
			</as-list>
		</div>
	</div>
</template>

<script>
	import AsList from "@/components/as-list";
	import {
		formatTime
	} from '@/utils/util.js'
	export default {
		components: {
			AsList,
		},
		data() {
			return {
				AccountList: [],
				value1: '',
				query: {
					user_id: [],
					sign_time: '',
					PageIndex: 1,
					PageSize: 10,
					Sort: [{
						"Field": "id",
						"Type": 0
					}]
				},
				listQuery: {
					PageIndex: 1,
					PageSize: 100,
					Sort: [{
						"Field": "id",
						"Type": 0
					}]
				}
			}
		},
		created() {
			this.getMoreAccountQuery()
		},
		methods: {
			getaccountQuery() {
				return new Promise((resolve, reject) => {
					this.API.accountQuery(this.listQuery).then(res => {
						resolve(res)
					})
				})
			},
			getMoreAccountQuery() {
				this.getaccountQuery().then(res => {
					let num = Math.ceil(res.data.total / this.listQuery.PageSize)
					if (num == 0) {
						this.listQuery.PageIndex = 1
					} else {
						this.listQuery.PageIndex++
					}
					this.getaccountQuery().then(val=>{
						this.AccountList =  res.data.rows.concat(val.data.rows) 
					})
					
				})
			},
			timeChange(val){
				let time = []
				for (let key in val) {
					time.push(formatTime(val[key], 'yyyy-MM-dd'))
				}
				this.query.sign_time=time.join('~');
				
			}
		}
	}
</script>

<style>
</style>
